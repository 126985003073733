<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1> Create Faq </h1>
        <div class="flex mt-20">
            <button class="table-lang-button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:600px;">
            <el-form ref="faq" :model="Faq" :rules="FaqRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Faq Category</p>
                        <el-form-item prop="category_id">
                            <el-select v-model="Faq.category_id" placeholder="Select" class="w-full">
                                <el-option v-for="item in FaqCategories" :key="item.id" :label="item.title_ua" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Question</p>
                        <el-form-item prop="question_ua">
                            <el-input placeholder="*Question" v-model="Faq.question_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Answer</p>
                        <el-form-item prop="answer_ua">
                            <el-input type="textarea" :rows="10" placeholder="Answer" v-model="Faq.answer_ua">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en" v-show="langName=='en'">
                    <div class="item mt-20">
                        <p> Question</p>
                        <el-form-item prop="question_en">
                            <el-input placeholder="*Question" v-model="Faq.question_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Answer</p>
                        <el-form-item prop="answer_en">
                            <el-input type="textarea" :rows="10" placeholder="Answer" v-model="Faq.answer_en">
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/faq')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="SaveFaq"> Редагувати</button>
                </div>
            </el-form>
        </div>
        
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            loading: false,
            value1: '',
            FaqCategories: [],
            Faq: {
                category_id: '',
                question_ua: '',
                question_en: '',
                answer_ua: '',
                answer_en: ''
            },
            FaqRules: {
                category_id: [{
                        required: true,
                        message: 'Please select',
                        trigger: 'change'
                    },],
                question_ua: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should be  5',
                        trigger: 'change'
                    }
                ],
                question_en: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should  5',
                        trigger: 'change'
                    }
                ],
                answer_ua: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should  5',
                        trigger: 'change'
                    }
                ],
                answer_en: [{
                        required: true,
                        message: 'Please enter title',
                        trigger: 'change'
                    },
                    {
                        min: 5,
                        message: 'Length should  5',
                        trigger: 'change'
                    }
                ],
            }
        }

    },
    methods: {
        SaveFaq() {
            this.$refs.faq.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.axios.post(`/admin/faq/${this.$route.params.id}`, this.Faq)
                        .then(res => {
                            console.log(res)
                            this.$router.push('/admin/faq')
                            this.$notify({
                                title: 'Save',
                                message: 'Ви успішно ввійшли',
                                type: 'success'
                            });
                        })
                        .catch(error => {
                            let ERRORS = error.response.data.message;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    if (this.Faq.question_ua &&  this.Faq.answer_ua && this.Faq.category_id) {
                        this.langName = "en"
                    }
                }
            })
        },
        GetFaqCategories() {
            this.axios.get('/admin/faq-category')
                .then(res => {
                    this.FaqCategories = res.data.data.data
                })
        },
        GetFaq() {
            this.loading = true
            this.axios.get(`/admin/faq/${this.$route.params.id}`)
                .then(res => {
                    for (let i of Object.keys(this.Faq)) {
                        this.Faq[i] = res.data.data[i]
                    }
                    this.Faq._method = 'PUT'
                })
                .catch(error => {
                    let ERRORS = error.response.data.message;
                    let keys = Object.keys(ERRORS);
                    let error_msg = ERRORS[keys[0]];
                    this.$notify.error({
                        title: 'Произошла ошибка',
                        message: error_msg ? error_msg : "Произошла ошибка",
                    });

                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.GetFaqCategories()
        this.GetFaq()
    }
}
</script>

<style scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}
</style>
